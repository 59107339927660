import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { JwtService } from '../../../core/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../../../core/services/user.service';
@Component({
  selector: 'app-leftbar',
  templateUrl: './leftbar.component.html',
  styleUrls: ['./leftbar.component.css']
})
export class LeftbarComponent implements OnInit {
  userData: any = {};
  constructor(
    private router: Router,
    private toastr: ToastrService,
    private jwtService: JwtService,
    private spinner: NgxSpinnerService,
    private dataService: UserService,
  ) { }

  ngOnInit(): void {
    let udata = this.jwtService.getUserData();
    if (udata) {
      this.userData=JSON.parse(udata);
    }
  }

}
