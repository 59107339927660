import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
@Injectable()
export class S3UploadService {
    constructor(
    ) { }
    uploadFile(file, filename, callback): any {
        const contentType = file.type;
        const bucket = new S3(
            {
                accessKeyId: environment.accessKeyId,
                secretAccessKey: environment.secretAccessKey,
                region: environment.region
            }
        );
        const params = {
            Bucket: environment.Bucket,
            Key: filename,
            Body: file,
            ACL: 'public-read',
            ContentType: contentType
        };
        bucket.upload(params, (err, data) => {
            if (err) {
                console.log(err)
                callback(err);
            } else {
                callback(null, data);
            }
        });
    }
}
