import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class EventService {

    constructor(
        private apiService: ApiService
    ) {
    }
    createEvent(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/createEvent', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    updateEvent(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/updateEvent', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    editEvent(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/editEvent', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    deleteEvent(data): Observable<any> {
        return this.apiService.delete('/api/v1/licensee/deleteEvent', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    getEvents(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/events', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    getSpeakers(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/speakers', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    addSpeaker(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/addSpeaker', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    editSpeaker(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/editSpeaker', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    updateSpeaker(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/updateSpeaker', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    
    deleteSpeaker(data): Observable<any> {
        return this.apiService.delete('/api/v1/licensee/deleteSpeaker', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    getAgenda(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/agenda', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    deleteAgenda(data): Observable<any> {
        return this.apiService.delete('/api/v1/licensee/deleteAgenda', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    allSpeakers(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/allSpeakers', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    editAgenda(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/editAgenda', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    createAgenda(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/createAgenda', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    updateAgenda(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/updateAgenda', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    
    
    getLocation(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/location', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    updateLocation(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/updateLocation', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    boothCategories(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/boothCategories', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    createBooth(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/createBooth', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    getBooths(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/getBooths', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    getAllBoothResource(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/getAllBoothResource', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    createBoothResource(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/createBoothResource', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    getBoothDetail(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/getBoothDetail', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    
    updateMeetingInfo(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/updateMeetingInfo', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    
    allBranding(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/allBranding', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    cometKeys(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/cometKeys', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    
    updateBranding(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/updateBranding', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    updateCometKeys(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/updateCometKeys', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    
    
    
    getFields(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/fields', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    updateFields(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/updateFields', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    
    deleteBooth(data): Observable<any> {
        return this.apiService.delete('/api/v1/licensee/deleteBooth', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    eventDetailsById(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/eventDetailsById', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    editBooth(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/editBooth', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    

    updateBooth(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/updateBooth', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    getBoothMeetings(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/getBoothMeetings', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    
    updateMeetingStatus(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/updateMeetingStatus', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    updateLMeetingStatus(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/updateLMeetingStatus', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    
    getAgendaFeedback(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/getAgendaFeedback', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    getAgendaRating(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/getAgendaRating', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    getEventFeedback(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/getEventFeedback', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    feedbackDetailsById(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/feedbackDetailsById', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }


    getEngagementData(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/getEngagementData', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    updateEngagementData(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/updateEngagementData', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    
    getLounge(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/getLounge', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    createLounge(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/createLounge', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    deleteLounge(data): Observable<any> {
        return this.apiService.delete('/api/v1/licensee/deleteLounge', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    
    getLoungeMeetings(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/getLoungeMeetings', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    getBoothFeedback(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/getBoothFeedback', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    deleteBoothResource(data): Observable<any> {
        return this.apiService.delete('/api/v1/licensee/deleteBoothResource', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    
    updateBoothResource(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/updateBoothResource', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    editBoothResource(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/editBoothResource', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    

    
}
