import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'forgot-password', loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
  { path: 'reset-password/:token', loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
  { path: 'events', loadChildren: () => import('./events/events.module').then(m => m.EventsModule) },
  { path: 'clients', loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule) },
  { path: 'speakers', loadChildren: () => import('./speakers/speakers.module').then(m => m.SpeakersModule) },
  { path: 'agenda', loadChildren: () => import('./agenda/agenda.module').then(m => m.AgendaModule) },
  { path: 'locations', loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule) },
  { path: 'event-users/:id', loadChildren: () => import('./event-users/event-users.module').then(m => m.EventUsersModule) },
  { path: 'booths', loadChildren: () => import('./booths/booths.module').then(m => m.BoothsModule) },
  { path: '', loadChildren: () => import('./visitors/visitors.module').then(m => m.VisitorsModule) },
  { path: 'branding', loadChildren: () => import('./branding/branding.module').then(m => m.BrandingModule) },
  { path: 'feedback-questions', loadChildren: () => import('./feedback-questions/feedback-questions.module').then(m => m.FeedbackQuestionsModule) },
  { path: 'notifications', loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule) },
  { path: 'event-feedback', loadChildren: () => import('./event-feedback/event-feedback.module').then(m => m.EventFeedbackModule) },
  { path: 'engagement', loadChildren: () => import('./engagement/engagement.module').then(m => m.EngagementModule) },
  { path: 'leaderboard', loadChildren: () => import('./leaderboard/leaderboard.module').then(m => m.LeaderboardModule) },
  { path: 'networking', loadChildren: () => import('./networking/networking.module').then(m => m.NetworkingModule) },
  { path: 'categories', loadChildren: () => import('./category/category.module').then(m => m.CategoryModule) },
  { path: 'tracking', loadChildren: () => import('./tracking/tracking.module').then(m => m.TrackingModule) },
  { path: 'chat-settings', loadChildren: () => import('./chat-seetings/chat-seetings.module').then(m => m.ChatSeetingsModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
