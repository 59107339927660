import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SocketService {
    socket;
    constructor() { }
    setupSocketConnection() {
        this.socket = io(environment.api_url);
      }
    public getMessages = () => {
        return Observable.create((observer) => {
            this.socket.on('notification', (message:any) => {
                observer.next(message);
            });
        });
    }
    public sendMessage(message) {
        this.socket.emit('notification', message);
    }
}