<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-clip-rotate-multiple" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
<!-- <ngx-loading-bar></ngx-loading-bar> -->
<div id="wrapper">
  <span *ngIf="isLogedIn()">
    <app-leftbar></app-leftbar>
    <app-header></app-header>
    <div class="clearfix"></div>
  </span>
  <router-outlet *ngIf="isOnline();else isOffline"></router-outlet>
  <ng-template #isOffline>
    <div class="content-wrapper">
      <section class="content">
        <div class="page-wrap">
          <h3 class="text-danger">No Internet Connection</h3>
        </div>
      </section>
    </div>
  </ng-template>
   <span *ngIf="isLogedIn()">
    <a href="javaScript:void();" class="back-to-top"><i class="fa fa-angle-double-up"></i> </a>
    <app-footer></app-footer>
    <div class="right-sidebar">
      <div class="switcher-icon">
        <i class="zmdi zmdi-settings zmdi-hc-spin"></i>
      </div>
      <div class="right-sidebar-content">

        <p class="mb-0">Gaussion Texture</p>
        <hr>

        <ul class="switcher">
          <li id="theme1"></li>
          <li id="theme2"></li>
          <li id="theme3"></li>
          <li id="theme4"></li>
          <li id="theme5"></li>
          <li id="theme6"></li>
        </ul>

        <p class="mb-0">Gradient Background</p>
        <hr>

        <ul class="switcher">
          <li id="theme7"></li>
          <li id="theme8"></li>
          <li id="theme9"></li>
          <li id="theme10"></li>
          <li id="theme11"></li>
          <li id="theme12"></li>
          <li id="theme13"></li>
          <li id="theme14"></li>
          <li id="theme15"></li>
        </ul>

      </div>
    </div>
  </span>
</div>
