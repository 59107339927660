<div id="sidebar-wrapper" data-simplebar="" data-simplebar-auto-hide="true">
  <div class="brand-logo">
    <a [routerLink]="['/']">
      <img src="assets/images/logo1.png" style="width: 150px;" alt="logo icon">
    </a>
  </div>
  <ul class="sidebar-menu do-nicescrol">
    <li class="sidebar-header">MAIN NAVIGATION</li>
    <li routerLinkActive="active">
      <a [routerLink]="['/dashboard']" routerLinkActive="active">
        <i class="zmdi zmdi-view-dashboard"></i> <span>Dashboard</span>
      </a>
    </li>
    <li routerLinkActive="active" *ngIf="userData.user_type==='LC'">
      <a [routerLink]="['/clients']" routerLinkActive="active">
        <i class="zmdi zmdi-accounts"></i> <span>Clients Management</span>
      </a>
    </li>
    <li routerLinkActive="active" *ngIf="userData.user_type==='LC'">
      <a [routerLink]="['/events']" routerLinkActive="active">
        <i class="fa fa-calendar"></i> <span>Events Management</span>
      </a>
    </li>
    <li routerLinkActive="active" *ngIf="userData.user_type==='EM'">
      <a [routerLink]="['/locations']" routerLinkActive="active">
        <i class="fa fa-map-marker"></i> <span>Locations Management</span>
      </a>
    </li>
    <li routerLinkActive="active" *ngIf="userData.user_type==='EM'">
      <a [routerLink]="['/branding']" routerLinkActive="active">
        <i class="fas fa-video"></i> <span>Branding Management</span>
      </a>
    </li>
    <li routerLinkActive="active" *ngIf="userData.user_type==='EM'">
      <a [routerLink]="['/engagement']" routerLinkActive="active">
        <i class="fas fa-gamepad"></i> <span>Engagement Management</span>
      </a>
    </li>

    <li routerLinkActive="active" *ngIf="userData.user_type==='EM'">
      <a [routerLink]="['/speakers']" routerLinkActive="active">
        <i class="fa fa-users"></i> <span>Speakers Management</span>
      </a>
    </li>
    <li routerLinkActive="active" *ngIf="userData.user_type==='EM'">
      <a [routerLink]="['/agenda']" routerLinkActive="active">
        <i class="fa fa-calendar"></i> <span>Agenda Management</span>
      </a>
    </li>
    <!-- <li routerLinkActive="active" *ngIf="userData.user_type==='EM'">
      <a [routerLink]="['/feedback-questions']" routerLinkActive="active">
        <i class="fa fa-question"></i> <span>Feedback Questions</span>
      </a>
    </li> -->
    <!-- <li routerLinkActive="active" *ngIf="userData.user_type==='EM'">
      <a [routerLink]="['/event-feedback']" routerLinkActive="active">
        <i class="fa fa-bullhorn"></i> <span>Feedback Management</span>
      </a>
    </li> -->
    <li routerLinkActive="active" *ngIf="userData.user_type==='EM'">
      <a [routerLink]="['/visitors']" routerLinkActive="active">
        <i class="fa fa-users"></i> <span>Visitors Management</span>
      </a>
    </li>
    <li routerLinkActive="active" *ngIf="userData.user_type==='EM'">
      <a [routerLink]="['/registration-fields']" routerLinkActive="active">
        <i class="fa fa-user-plus"></i> <span>Registration Fields</span>
      </a>
    </li>
    <li routerLinkActive="active" *ngIf="userData.user_type==='BM'">
      <a [routerLink]="['/categories']" routerLinkActive="active">
        <i class="fas fa-list"></i> <span>Category Management</span>
      </a>
    </li>
    <li routerLinkActive="active" *ngIf="userData.user_type==='BM'">
      <a [routerLink]="['/booths']" routerLinkActive="active">
        <i class="fas fa-person-booth"></i> <span>Booths Management</span>
      </a>
    </li>
    <li routerLinkActive="active" *ngIf="userData.user_type==='EM'">
      <a [routerLink]="['/networking']" routerLinkActive="active">
        <i class="fa fa-handshake-o"></i> <span>Networking Management</span>
      </a>
    </li>
    <li routerLinkActive="active" *ngIf="userData.user_type==='EM'">
      <a [routerLink]="['/leaderboard']" routerLinkActive="active">
        <i class="fas fa-trophy"></i> <span>Leaderboard</span>
      </a>
    </li>
    <li routerLinkActive="active" *ngIf="userData.user_type==='EM'">
      <a [routerLink]="['/notifications']" routerLinkActive="active">
        <i class="fas fa-bell"></i> <span>Notifications</span>
      </a>
    </li>
    <li routerLinkActive="active" *ngIf="userData.user_type==='EM'">
      <a [routerLink]="['/tracking']" routerLinkActive="active">
        <i class="fa fa-line-chart"></i> <span>Analytics</span>
      </a>
    </li>
    <li routerLinkActive="active" *ngIf="userData.user_type==='CM'">
      <a [routerLink]="['/chat-settings']" routerLinkActive="active">
        <i class="fas fa-comment"></i> <span>Chat Management</span>
      </a>
    </li>
  </ul>

</div>