import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(
        private apiService: ApiService
    ) {
    }
    loginUser(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/login', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    logout(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/logout', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    dashboardStatics(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/dashboardStatics', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    forgotPassword(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/forgotPassword', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    createClient(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/createClient', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    updateClient(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/updateClient', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    editClient(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/editClient', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    deleteClient(data): Observable<any> {
        return this.apiService.delete('/api/v1/licensee/deleteClient', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    resetPassword(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/resetPassword', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    getClients(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/clients', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    allClients(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/allClients', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    getEventOrganisers(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/EventOrganisers', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    addOrganiser(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/addOrganiser', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    organisers(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/organisers', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    createEventUser(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/createEventUser', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    getEventUsers(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/eventUsers', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    getVisitors(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/Visitors', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    getFeedbacks(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/feedback', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    createFeedback(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/createFeedback', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    deleteFeedbackQuestion(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/deleteFeedbackQuestion', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    importUsers(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/importUsers', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    updateUserStatus(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/updateUserStatus', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    notifications(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/notifications', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    sendNotification(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/sendNotification', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }


    createCriteria(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/createCriteria', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    updateCriteria(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/updateCriteria', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    editCriteria(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/editCriteria', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    deleteCriteria(data): Observable<any> {
        return this.apiService.delete('/api/v1/licensee/deleteCriteria', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    getCriteria(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/getCriteria', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    getBoothCategories(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/getBoothCategories', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }


    createBoothCategory(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/createBoothCategory', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    editBoothCategory(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/editBoothCategory', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    deleteBoothCategory(data): Observable<any> {
        return this.apiService.delete('/api/v1/licensee/deleteBoothCategory', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    updateBoothCategory(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/updateBoothCategory', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    deleteVisitor(data): Observable<any> {
        return this.apiService.delete('/api/v1/licensee/deleteVisitor', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    updateVisitor(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/updateVisitor', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }

    editVisitor(data): Observable<any> {
        return this.apiService.get('/api/v1/licensee/editVisitor', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }
    getTracking(data): Observable<any> {
        return this.apiService.post('/api/v1/licensee/getTracking', data)
            .pipe(map(
                res => {
                    return res;
                }
            ));
    }


}
